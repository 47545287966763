body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-buttons {
  border: 0;
  background: transparent;
  color: #212529 !important;
  margin: 12px 24px;

  /* border-radius: 20px; */
}

.custom-buttons.active {
  border-bottom: 3px solid #198754;
  color: #198754 !important;
}

.image-box {
  transition: 0.2s;
}

.image-box:hover {
  box-shadow: 0 0 5px #000;
  transform: scale(1.05);
}